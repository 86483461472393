import {
  Box,
  DeleteOutlineIcon,
  IconButton,
  Typography,
  enerbitColors,
  formatterPeso,
  serviceName,
} from "@enerbit/base";
import moment from "moment";
import { DocumentGrouped } from "../../models/DocumentsGrouped";

export type CardResumeItemProps = {
  documentGroup: DocumentGrouped;
  onDelete: () => void;
};

export const CardResumeItem = ({
  documentGroup,
  onDelete,
}: CardResumeItemProps) => {
  return (
    <Box className="box-card-resume">
      <Box className="box-title-card-resume">
        <Typography
          variant="subtitle1"
          fontSize={16}
          fontWeight="bold"
          color={enerbitColors.neutral[700]}
        >
          {serviceName[documentGroup.name]} (
          {moment(documentGroup.invoice_period).format("MMMM YYYY")})
        </Typography>
        <Typography
          variant="subtitle1"
          fontSize={12}
          color={enerbitColors.neutral.main}
        >
          {documentGroup.estate?.personalization ??
            documentGroup.estate?.address ??
            ""}
        </Typography>
      </Box>
      <Typography
        variant="h6"
        fontWeight="bold"
        color={enerbitColors.neutral[700]}
      >
        {formatterPeso.format(documentGroup.payable_amount)}
      </Typography>
      <IconButton
        aria-label="Eliminar"
        sx={{ width: "24px", height: "24px" }}
        color="error"
        onClick={onDelete}
      >
        <DeleteOutlineIcon />
      </IconButton>
    </Box>
  );
};
