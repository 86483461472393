import {
  Box,
  Button,
  CheckIcon,
  CircularProgress,
  DialogInformation,
  FilterListIcon,
  KeyboardArrowDownIcon,
  LoadingButton,
  Typography,
  enerbitColors,
  formatterPeso,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  getDocumentsGrouped,
  getTotalDue,
} from "../../features/actions/actions";
import { getUserById } from "../../features/actions/users/users.actions";
import {
  onAllUnPayedGroupsCheckChanged,
  onCleanPayedGroups,
  onCleanUnPayedGroups,
  onFiltersCleaned,
} from "../../features/invoice/invoice";
import { StateStorage } from "../../models/invoiceState";
import { InvoicesMicroProps } from "../../root.component";
import { AppDispatch } from "../../store/store";
import { CustomizedAccordion } from "../components/CustomizedAccordion";
import { DetailForm } from "../components/DetailForm";
import { FiltersDialog } from "../components/FiltersDialog";
import { ApplyPaymentFormDialog } from "./components/ApplyPaymentFormDialog";
import { ResumeDialog } from "./components/ResumeDialog";

const SectionInvoices = ({ user_id }: InvoicesMicroProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [openDialogFilter, setOpenDialogFilter] = useState(false);
  const [openResumeDialog, setOpenResumeDialog] = useState(false);
  const [openApplyPaymentDialog, setOpenApplyPaymentDialog] = useState(false);
  const [isAllDocumentsUnPayedSelected, setIsAllDocumentsUnPayedSelected] =
    useState<boolean>(false);
  const [totalGroupsSelected, setTotalGroupsSelected] = useState<number>(0);
  const dispatch = useDispatch<AppDispatch>();
  const {
    isLoadingDocumentGroupedUnPayed,
    documentsGroupedUnPayed,
    documentsGroupedPayed,
    totalDue,
    isLoadingTotalDue,
    filters,
    appUser,
    timesPayedFeched,
    timesUnPayedFeched,
    isLoadingDocumentGroupedPayed,
  } = useSelector((state: StateStorage) => state.invoiceState);

  const navigate = useNavigate();

  useEffect(() => {
    setFirstTime(false);
    dispatch(
      getDocumentsGrouped({
        invoice_period: moment().format("YYYY-MM"),
        is_paid: true,
        user_id,
        reset_data: firstTime,
      })
    );
    dispatch(
      getDocumentsGrouped({
        invoice_period: moment().format("YYYY-MM"),
        is_paid: false,
        user_id,
        reset_data: firstTime,
      })
    );
  }, []);

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < documentsGroupedUnPayed.document_groups.length; i++) {
      if (documentsGroupedUnPayed.document_groups[i].isSelected) {
        total += documentsGroupedUnPayed.document_groups[i].payable_amount;
      }
    }
    setTotalGroupsSelected(total);
  }, [documentsGroupedUnPayed]);

  useEffect(() => {
    setIsAllDocumentsUnPayedSelected(
      documentsGroupedUnPayed.document_groups.every(
        (documentGroup) => documentGroup.isSelected
      )
    );
  }, [documentsGroupedUnPayed]);

  useEffect(() => {
    if (
      documentsGroupedUnPayed.next_invoice_period &&
      timesUnPayedFeched < 3 &&
      documentsGroupedUnPayed.user_id == user_id
    ) {
      dispatch(
        getDocumentsGrouped({
          invoice_period: moment(
            documentsGroupedUnPayed.next_invoice_period
          ).format("YYYY-MM"),
          is_paid: false,
          user_id,
          reset_data: false,
        })
      );
    }
  }, [documentsGroupedUnPayed]);

  useEffect(() => {
    if (
      documentsGroupedPayed.next_invoice_period &&
      timesPayedFeched < 3 &&
      documentsGroupedPayed.user_id == user_id
    ) {
      dispatch(
        getDocumentsGrouped({
          invoice_period: moment(
            documentsGroupedPayed.next_invoice_period
          ).format("YYYY-MM"),
          is_paid: true,
          user_id,
          reset_data: false,
        })
      );
    }
  }, [documentsGroupedPayed]);

  useEffect(() => {
    return () => {
      dispatch(onCleanPayedGroups());
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(onCleanUnPayedGroups());
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(onFiltersCleaned());
    };
  }, []);

  useEffect(() => {
    if (!appUser) {
      dispatch(getUserById(user_id));
    }
  }, []);

  useEffect(() => {
    dispatch(getTotalDue({ user_id }));
  }, []);

  const handleClickOpen = () => {
    setOpenDialogFilter(true);
  };

  const handleCloseFilterDialog = () => {
    setOpenDialogFilter(false);
  };

  const handleCloseApplyPaymentDialog = () => {
    setOpenApplyPaymentDialog(false);
  };

  const handleCloseResumeDialog = () => {
    setOpenResumeDialog(false);
  };

  const handleResume = () => {
    setOpenResumeDialog(true);
  };

  return (
    <Box>
      <Box className="main-content" paddingBottom="76px">
        <Box className="content-title">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography
              fontSize={20}
              display="inline"
              color={enerbitColors.primary.main}
            >
              <span style={{ fontWeight: "bold" }}>Facturas sin pagar</span>{" "}
              {totalDue &&
                !isLoadingTotalDue &&
                `(deuda total: ${formatterPeso.format(totalDue.total_due)})`}
            </Typography>
            {isLoadingTotalDue && !totalDue && (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            )}
          </Box>
          <Box className="content-title">
            <Button
              variant={
                isAllDocumentsUnPayedSelected &&
                documentsGroupedUnPayed.document_groups.length > 0
                  ? "contained"
                  : "outlined"
              }
              startIcon={
                isAllDocumentsUnPayedSelected &&
                documentsGroupedUnPayed.document_groups.length > 0 && (
                  <CheckIcon />
                )
              }
              color="primary"
              onClick={() => {
                dispatch(
                  onAllUnPayedGroupsCheckChanged(!isAllDocumentsUnPayedSelected)
                );
              }}
            >
              Seleccionar todo
            </Button>
            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              endIcon={<KeyboardArrowDownIcon />}
              color="primary"
              onClick={handleClickOpen}
            >
              <span style={{ fontWeight: "normal" }}>Filtro</span>
            </Button>
          </Box>
        </Box>
        {documentsGroupedUnPayed.document_groups.map((documentGrouped) => {
          return (
            <CustomizedAccordion
              key={uuidv4()}
              openDialogInformation={() => {
                setIsDrawerOpen(true);
              }}
              documentGrouped={documentGrouped}
            />
          );
        })}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {documentsGroupedUnPayed.next_invoice_period && (
            <LoadingButton
              variant="outlined"
              loading={isLoadingDocumentGroupedUnPayed}
              disabled={isLoadingDocumentGroupedUnPayed}
              onClick={() => {
                let invoice_period = moment().format("YYYY-MM");
                if (documentsGroupedUnPayed.next_invoice_period) {
                  invoice_period = moment(
                    documentsGroupedUnPayed.next_invoice_period
                  ).format("YYYY-MM");
                }
                dispatch(
                  getDocumentsGrouped({
                    invoice_period: invoice_period,
                    is_paid: false,
                    user_id,
                    reset_data: firstTime,
                    service_account_id: filters.serviceAccountId,
                    consecutive: filters.consecutive?.toString(),
                    stamp_code: filters.cufe,
                  })
                );
              }}
            >
              Ver más
            </LoadingButton>
          )}
        </Box>
        <Box className="content-title">
          <Typography
            fontSize={20}
            display="inline"
            color={enerbitColors.primary.main}
            fontWeight="bold"
          >
            Últimas facturas pagadas
          </Typography>
          <Box className="content-title">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate(`/invoices/${user_id}/detail`);
              }}
            >
              Ver todas las facturas
            </Button>
          </Box>
        </Box>
        {documentsGroupedPayed.document_groups.map((documentGrouped) => {
          return (
            <CustomizedAccordion
              key={uuidv4()}
              openDialogInformation={() => {
                setIsDrawerOpen(true);
              }}
              documentGrouped={documentGrouped}
              canCheck={false}
            />
          );
        })}
        {isLoadingDocumentGroupedPayed && (
          <Box display="flex" width="100%" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        <FiltersDialog
          handleClose={handleCloseFilterDialog}
          open={openDialogFilter}
          userId={user_id}
        />
        <ResumeDialog
          handleClose={handleCloseResumeDialog}
          open={openResumeDialog}
          onApplyPaymentClicked={() => {
            handleCloseResumeDialog();
            setOpenApplyPaymentDialog(true);
          }}
        />
        <ApplyPaymentFormDialog
          handleClose={handleCloseApplyPaymentDialog}
          open={openApplyPaymentDialog}
          userId={user_id}
        />
        <DialogInformation
          onCloseAction={() => {}}
          content={<DetailForm />}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      </Box>
      {totalGroupsSelected != 0 && (
        <Box className="sticky-bar animate__animated animate__slideInUp animate__faster">
          <Box className="group-text-sticky-bar">
            <Typography variant="subtitle1" color={enerbitColors.neutral[300]}>
              Total grupo de pagos
            </Typography>
            <Typography
              variant="h5"
              color={enerbitColors.primary.main}
              fontWeight="bold"
            >
              {formatterPeso.format(totalGroupsSelected)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Button variant="outlined" color="primary" onClick={handleResume}>
              Ver resumen
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenApplyPaymentDialog(true);
              }}
            >
              Aplicar pago
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SectionInvoices;
